<template>
  <div class="faq">
    <FaqHero />
    <FaqContent />
    <Footer />
  </div>
</template>

<script>
import FaqHero from "@/components/Faq/Hero.vue";
import FaqContent from "@/components/Faq/Content.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    FaqHero,
    FaqContent,
    Footer,
  },
  mounted() {
    const meta = document.querySelector('meta[name="robots"]');
    if (this.$route.meta.robots) {
      meta.setAttribute("content", this.$route.meta.robots);
    }
  },
};
</script>

<style lang="scss" scoped>
.faq {
  background: $blue-primary;
  padding-top: 60px;
}
</style>
