<template>
  <section id="content">
    <div class="movetop">
      <div class="container">
        <div class="list">
          <SearchInput nome="Pesquisar">
            <input type="text" v-model="search" placeholder=" ">
          </SearchInput>
          <FaqItem v-for="(question, i) in filteredList" :key="i" :question="question.question" :description="question.description" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import questions from "@/assets/json/questions.json";
import FaqItem from "@/components/Faq/Item.vue";
import SearchInput from "@/components/UI/Form/SearchInput.vue";

export default {
  components: {
    FaqItem,
    SearchInput,
  },
  data() {
    return {
      search: "",
    };
  },
  computed: {
    questions() {
      return questions;
    },
    filteredList() {
      return this.questions.filter(post => post.question.toLowerCase().includes(this.search.toLowerCase()));
    },
  },
};
</script>

<style lang="scss" scoped>
#content {
  background: $blue-primary;
  margin-bottom: -80px;

  @media (max-width: 576px) {
    margin-bottom: -1px;
  }

  .movetop {
    position: relative;
    top: -80px;

    @media (max-width: 576px) {
      top: -1px;
    }
  }

  .container {
    padding-top: 49px;
    padding-bottom: 99px;
    background: $white;
    border-top: 7px solid $blue-primary;

    @media (max-width: 576px) {
      padding-top: 30px;
      padding-bottom: 50px;
    }
  }

  .list {
    padding: 0 180px;

    @media (max-width: 1200px) {
      padding: 0 40px;
    }

    @media (max-width: 576px) {
      padding: 0;
    }

    .searchInput {
      max-width: 300px;
      margin: 0 auto;
      margin-bottom: 50px;

      @media (max-width: 576px) {
        margin-bottom: 30px;
      }
    }
  }
}
</style>
