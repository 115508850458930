<template>
  <div id="item" @click="opened = !opened">
    <div class="question" :class="{rotate: opened}">
      {{ question }}
      <font-awesome-icon :icon="['fas', 'chevron-down']" />
    </div>
    <transition mode="out-in" enter-active-class="animated fadeIn">
      <div class="content" show v-if="opened">
        <div class="description" v-html="description">
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["question", "description"],
  data() {
    return {
      opened: false,
    };
  },
};
</script>

<style lang="scss" scoped>
#item {
  font-family: $font;
  box-sizing: border-box;
  border-bottom: 1px solid $blue-primary;
  cursor: pointer;

  .question {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: normal;
    font-weight: $semibold;
    font-size: 16px;
    line-height: 20px;
    color: $blue-primary;
    padding: 20px 10px;
    box-sizing: border-box;
    text-align: left;

    &.rotate {
      svg {
        transform: rotate(180deg);
        transition: 0.2s;
      }
    }

    svg {
      font-size: 16px;
      transition: 0.2s;
      margin-left: 10px;
    }
  }

  .content {
    font-style: normal;
    font-weight: $medium;
    font-size: 14px;
    line-height: 22px;

    .description {
      padding: 0 10px;
      text-align: left;
      color: $black;
      margin-bottom: 14px;
    }

    .chat {
      text-align: center;
      color: $black;
      display: none;

      .icon {
        background: $blue-primary;
        width: 90px;
        height: 30px;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        padding-left: 5px;
        padding-right: 20px;
        box-sizing: border-box;
        align-items: center;
        font-style: normal;
        font-weight: $semibold;
        font-size: 11px;
        line-height: 13px;
        color: $white-secondary;
        margin: 8px auto;

        img {
          width: 22px;
          height: 22px;
        }
      }
    }
  }
}
</style>
